@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SpaceGrotesk-Light';
  src: url('../public/fonts/SpaceGrotesk-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SpaceGrotesk-Medium';
  src: url('../public/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-c {
  animation-name: ani-c;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes ani-c {
  0%   { color:#001C6D; }
  25%  { color:#FF8202; }
  50%  { color:#04C4B0; }
  75%  { color:#01A0BC; }
  100% { color:#212121; }
}

.text-r {
  animation-name: ani-r;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes ani-r {  
  0%  { color:#FF8202; }
  25%  { color:#04C4B0; }
  50%  { color:#01A0BC; }
  70% { color:#212121; }
  100%   { color:#001C6D; }
}

.text-e {
  animation-name: ani-e;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes ani-e {  
  0%  { color:#04C4B0; }
  25%  { color:#01A0BC; }
  50% { color:#212121; }
  70%   { color:#001C6D; }
  100%  { color:#FF8202; }
}

.text-a {
  animation-name: ani-a;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes ani-a {  
  0%  { color:#01A0BC; }
  25% { color:#212121; }
  50%   { color:#001C6D; }
  70%  { color:#FF8202; }
  100%  { color:#04C4B0; }
}

.text-t {
  animation-name: ani-t;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes ani-t {   
  0% { color:#212121; }
  25%   { color:#001C6D; }
  50%  { color:#FF8202; }
  70%  { color:#04C4B0; }
  100%  { color:#01A0BC; }
}

.text-u {
  visibility: hidden;
  animation-name: ani-u;
  animation-delay: .3s;
  animation-fill-mode: both;
}

@keyframes ani-u {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-t1 {
  visibility: hidden;
  animation-name: ani-t1;
  animation-delay: .5s;
  animation-fill-mode: both;
}

@keyframes ani-t1 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-o {
  visibility: hidden;
  animation-name: ani-o;
  animation-delay: .7s;
  animation-fill-mode: both;
}

@keyframes ani-o {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-f {
  visibility: hidden;
  animation-name: ani-f;
  animation-delay: .9s;
  animation-fill-mode: both;
}

@keyframes ani-f {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-t2 {
  visibility: hidden;
  animation-name: ani-t2;
  animation-delay: 1.1s;
  animation-fill-mode: both;
}

@keyframes ani-t2 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-h {
  visibility: hidden;
  animation-name: ani-h;
  animation-delay: 1.3s;
  animation-fill-mode: both;
}

@keyframes ani-h {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-e {
  visibility: hidden;
  animation-name: ani-e;
  animation-delay: 1.5s;
  animation-fill-mode: both;
}

@keyframes ani-e {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-b {
  visibility: hidden;
  animation-name: ani-b;
  animation-delay: 1.7s;
  animation-fill-mode: both;
}

@keyframes ani-b {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-o2 {
  visibility: hidden;
  animation-name: ani-o2;
  animation-delay: 1.9s;
  animation-fill-mode: both;
}

@keyframes ani-o2 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-x {
  visibility: hidden;
  animation-name: ani-x;
  animation-delay: 2.1s;
  animation-fill-mode: both;
}

@keyframes ani-x {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.dot1 {
  visibility: hidden;
  animation-name: dot1;
  animation-delay: 2.3s;
  animation-fill-mode: both;
}

@keyframes dot1 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.dot2 {
  visibility: hidden;
  animation-name: dot2;
  animation-delay: 2.5s;
  animation-fill-mode: both;
}

@keyframes dot2 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.dot3 {
  visibility: hidden;
  animation-name: dot3;
  animation-delay: 2.7s;
  animation-fill-mode: both;
}

@keyframes dot3 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-s1 {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-s1;
  animation-delay: 2.9s;
  animation-fill-mode: both;
}

@keyframes text-s1 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-o1 {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-o1;
  animation-delay: 3.1s;
  animation-fill-mode: both;
}

@keyframes text-o1 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-l {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-l;
  animation-delay: 3.3s;
  animation-fill-mode: both;
}

@keyframes text-l {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-u1 {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-u1;
  animation-delay: 3.5s;
  animation-fill-mode: both;
}

@keyframes text-u1 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-t3 {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-t3;
  animation-delay: 3.7s;
  animation-fill-mode: both;
}

@keyframes text-t3 {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-i {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-i;
  animation-delay: 3.9s;
  animation-fill-mode: both;
}

@keyframes text-i {   
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.text-o4 {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-o4;
  animation-delay: 4.1s;
  animation-fill-mode: both;
  /* animation-iteration-count: 2;
  animation-direction: alternate-reverse;   */
}

@keyframes text-o4 {   
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.text-n {
  color: #FF8202;
  visibility: hidden;
  animation-name: text-n;
  animation-delay: 4.3s;
  animation-fill-mode: both;
  animation-duration: 1s;
}

@keyframes text-n {   
  0% {
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.carousel-item-gap {
  padding-right: 30px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.carousel-item-side {
  width: 100%;
}

.carousel-container video {
  border-radius: 50px;
  overflow: hidden;
  -webkit-border-radius: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
