/* Slideshow */

.slideshow {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    max-width: 100%;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    /* height: 100vh; */
    width: 100%;
}
  